import { createApp, h } from 'vue'
import App from './App.vue'
import VueToast from 'vue-toast-notification';

import 'vue-toast-notification/dist/theme-sugar.css';

const app = createApp({
    render () { return h(App); },
});

app.use(VueToast)
app.mount('#app')
app.config.devtools = true;

