import axios from "axios";
//axios.defaults.baseURL = "http://127.0.0.1:8000";
// axios.defaults.baseURL = "http://edynamicsadmin.in";
// const StorageObjUserRef = localStorage.user_reference;
// const StorageObjClient = localStorage.client_info ? JSON.parse(localStorage.client_info) : '';
// const client = StorageObjClient.maa1 ? StorageObjClient.maa1 : '';
// const user = localStorage.user;


export default class ApiService {

    StorageObjUserRef = localStorage.user_reference;
    StorageObjClient = localStorage.client_info ? JSON.parse(localStorage.client_info) : '';
    client = this.StorageObjClient.maa1 ? this.StorageObjClient.maa1 : '';
    user = localStorage.user;
    isLoginFromAdmin = localStorage.LoginAsClientInClient ? localStorage.LoginAsClientInClient : '';
    loginUserId = localStorage.loginUserId ? localStorage.loginUserId : '';


    HeaderParam = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "content-type": "application/json",
        "Accept": "application/json"
    };

    AuthenticatedUserHeader = {
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'content-type': 'application/json',
        'Accept': 'application/json',
        'Authorization': "Bearer " + this.StorageObjUserRef,
        'client': this.client,
        'user': this.user,
        'isLoginFromAdmin' :this.isLoginFromAdmin,
        'loginUSerId' : this.loginUserId
    };

    commonapiall = (url, params, method, type, item) => {
        return axios(url, {
            method: method,
            headers: params,
            data: item
        })
            .then(response => {
                if (response.data.status === 403) {
                    this.logout();
                }
                else if (response.data.status === 423 && response.data.logout_reason === 4 ) {
                    localStorage.setItem("userLockAttempt", 1);
                    localStorage.setItem("userLockAttemptReason", response.data.message);
                }
                // else if (response.data.status === 401 ) {
                    //  this.logoutonsessiontimeout({ logout_reason: response.data.logout_reason });
                  
                    // localStorage['logout_reason'] = response.data.message;
                    // localStorage['error_toaster_display'] = 1;
                    //location.reload();
                    // this.logoutsession();
                // }
                else {
                    return response.data;
                }
            })
            .catch(error => {
                throw error;
            });
    };

    logoutsession(item) {

        let URL = "/api/u/logoutsession";
        return axios(URL, {
            method: 'POST',
            headers: this.AuthenticatedUserHeader,
            data: item,
        })
            .then(response => {
                localStorage.clear();
                window.location.href = "/";
                return response.data;
            })
            .catch(error => {
                throw error;
            });
    }
    logout(item) {

        localStorage.clear();
        let URL = "/api/v1/logout";
        return axios(URL, {
            method: 'POST',
            headers: this.AuthenticatedUserHeader,
            data: item,
        })
            .then(response => {

                localStorage.clear();
                window.location.href = "/";
                return response.data;
            })
            .catch(error => {
                throw error;
            });
    }

    checkAccessCode(item) {
        let URL = "/api/u/checkAccessCode";
        return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
    }
}

