<template>
  <div class="custom-css-centre-div">
    <div class="access-code-section-outer d-flex justify-content-center">
      <div class="text-center mt-auto mb-auto" :style="{ width: '392px' }">
        <div class="panel-card-access d-flex flex-column">
          <div class="access-header px-3 pt-2 pb-1">ACCESS CODE</div>
          <div class="access-sub-header">Welcome to Countless Bi</div>
          <div class="d-flex flex-column px-3">
            <div class="form-floating mb-0 mt-3">
              <input
                type="text"
                v-model="accessvalue"
                maxlength="15"
                v-on:keyup.enter="checkuseraccesscode()"
                class="form-control"
                id="accesscodeInput"
                autocomplete="off"
                placeholder="Enter Access Code"
              />
            </div>
            <div class="custom-error" v-if="v$.accessvalue.$error">
              {{ v$.accessvalue.$errors[0].$message }}
            </div>
            <div class="custom-error" v-if="accessvalue != '' && accesscodemsg">
              {{ this.accesscodemsg }}
            </div>
            <div class="my-4">
              <button
                class="btn access-submit-btn"
                type="submit"
                @click="checkuseraccesscode()"
                :disabled="showloader"
              >
                <span>Submit</span>
                <!-- <div
                  class="spinner-border text-light custom-spinner-loader-btn"
                  role="status"
                  v-if="showloader"
                ></div> -->
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../service/ApiService";

export default {
  data() {
    return {
      v$: useValidate(),
      accessvalue: "",
      showloader: false,
      baseUrl: window.location.origin,
    };
  },
  watch: {
    accessvalue() {
      this.v$.$validate();
    },
  },
  validations() {
    return {
      accessvalue: {
        required: helpers.withMessage("Please enter Access Code", required),
      },
    };
  },

  mounted() {},

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  methods: {
    checkuseraccesscode() {
      this.v$.$validate();
      let fields = {};
      fields["access_code"] = this.accessvalue;
      if (!this.v$.$error) {
        this.showloader = true;
        this.ApiService.checkAccessCode(fields).then((items) => {
          if (items.status === 200) {
            this.showloader = false;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
          } else {
            this.showloader = false;
            this.accesscodemsg = items.message;
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.access-code-section-outer {
  height: 100vh;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.access-code-section-outer .panel-card-access {
  border: 1.5px solid #e4e4e4;
  border-radius: 6px;
  text-align: center;
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
}

.access-code-section-outer .access-header {
  margin-top: -3rem;
  color: #ffffff;
  border: 1.5px solid #e4e4e4;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 1;
  background: #f76100;
  font-family: "AcuminPro-Bold";
}

.access-code-section-outer .access-sub-header {
  font-size: 1.5rem;
  margin: 1.5rem 0 1rem 0;
  font-family: "AcuminPro-SemiBold";
  line-height: 1.2;
}

.access-code-section-outer .access-submit-btn {
  width: 100%;
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  padding: 9px 11px;
  font-family: "AcuminPro-Regular";
  margin-top: 4px;
  cursor: pointer;
}

.access-code-section-outer .access-submit-btn:focus,
.access-code-section-outer .access-submit-btn:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.access-code-section-outer .form-floating .form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c0c3c9;
  border-radius: 4px;
  font-family: "AcuminPro-Regular";
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #0b294b;
}

.access-code-section-outer .form-floating .form-control:focus {
  box-shadow: none !important;
  outline: 0;
  text-decoration: none;
  background-color: #fff;
  border-color: #80bdff !important;
}

.access-code-section-outer .form-floating .form-control:hover {
  border: 1px solid #bcbec1;
}

.access-code-section-outer .custom-error {
  color: #de2525;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.52px;
  line-height: 20px;
}
.custom-modal-spinner-loader-access {
  background-color: #fff;
  transition-duration: 0.2s;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #9cb5d9;
}
.custom-css-centre-div {
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.custom-spinner-loader-btn {
  width: 15px;
  height: 15px;
  border: 2px solid #ced9e9;
  border-right-color: transparent;
}
</style>
